
/* Dependencies */
import { defineComponent } from 'vue';
import {Form, FormActions} from 'vee-validate';

// Components
import Select from '../../Atoms/Select/Select.vue';
import Input from '../../Atoms/Input/Input.vue';
import CheckboxList from '../../Molecules/CheckboxList/CheckboxList.vue';

// Models
import {
  formSchema,
  titleOptions,
  businessTypeOptions,
  turnoverOptions,
  contactOptions,
  countryCodeOptions,
  salesChannelOptions,
  marketingOptions,
  acknowledgementOptions,
} from './PartnerForm.model';
import MarketingPreference from "@/components/Molecules/MarketingPreference/MarketingPreference.vue";

/**
 * PartnerForm component
 */
export default defineComponent({
  components: {
    MarketingPreference,
    Form,
    Select,
    Input,
    CheckboxList,
  },
  props: {
    partnerId: String,
    default: () => 'No Partner Id Given'
  },
  data() {
    return {
      formSchema,
      titleOptions,
      businessTypeOptions,
      turnoverOptions,
      contactOptions,
      countryCodeOptions,
      salesChannelOptions,
      marketingOptions,
      acknowledgementOptions,
    };
  },

  methods: {
    updateMarketingPreference(e: any, setFieldValue: FormActions<Record<string, string>>['setFieldValue']) {
      const state = e.target.checked ? "1" : "0"

      marketingOptions.forEach((option) => setFieldValue(option.name, state))
    },

    handleCheckboxValueChange(e: any, setFieldValue: FormActions<Record<string, string>>['setFieldValue']) {
      const state = e.target.checked ? "1" : "0"

      setFieldValue(e.target.name, state)
    }
  },

  computed: {
    submitUrl() {
      return process.env.VUE_APP_SF_FORM_URL;
    },

    returnUrl() {
      return process.env.VUE_APP_SF_FORM_RETURN_URL;
    }
  }
});
