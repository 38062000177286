import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMessage = _resolveComponent("ErrorMessage", true)!

  return (_openBlock(), _createBlock(_component_ErrorMessage, {
    name: _ctx.name,
    class: "text-error text-sm mt-2",
    as: "span",
    id: `${_ctx.id}_help-text`
  }, null, 8, ["name", "id"]))
}