import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value", "id", "name", "onChange"]
const _hoisted_2 = { class: "ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("label", null, [
    _createVNode(_component_Field, {
      id: _ctx.option.id,
      name: _ctx.option.name,
      value: "0"
    }, {
      default: _withCtx(({field,handleChange}) => [
        _createElementVNode("input", _mergeProps(field, {
          type: "checkbox",
          value: field.value,
          id: _ctx.option.id,
          name: _ctx.option.name,
          onChange: $event => _ctx.handleInputChange($event, handleChange)
        }), null, 16, _hoisted_1)
      ]),
      _: 1
    }, 8, ["id", "name"]),
    _createElementVNode("span", _hoisted_2, [
      (_ctx.$slots.default)
        ? _renderSlot(_ctx.$slots, "default", { key: 0 })
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(_ctx.option.value), 1)
          ], 64))
    ])
  ]))
}