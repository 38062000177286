import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "text-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", {
      for: _ctx.id,
      class: "text-primary text-base font-medium"
    }, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_ctx.required)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
        : _createCommentVNode("", true)
    ], 8, _hoisted_1),
    _createVNode(_component_Field, {
      name: _ctx.name,
      value: _ctx.value,
      id: _ctx.id,
      type: _ctx.type,
      "aria-describedby": `${_ctx.id}_help-text`,
      class: _normalizeClass([[
      Object.keys(_ctx.errors).includes(_ctx.name)
        ? 'border-2 border-solid border-error'
        : '',
    ], "block w-full h-9 mt-1 px-2 border-form-border border-solid border rounded-sm"]),
      required: _ctx.required ? _ctx.required : false
    }, null, 8, ["name", "value", "id", "type", "aria-describedby", "class", "required"]),
    (Object.keys(_ctx.errors).includes(_ctx.name))
      ? (_openBlock(), _createBlock(_component_ErrorMessage, {
          key: 0,
          name: _ctx.name,
          id: `${_ctx.id}_help-text`
        }, null, 8, ["name", "id"]))
      : _createCommentVNode("", true)
  ], 64))
}