
/* Dependencies */
import { defineComponent } from 'vue';
import { Field } from 'vee-validate';

// Components
import ErrorMessage from '../ErrorMessage/ErrorMessage.vue';

/**
 * Select component
 */
export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    Field,
    ErrorMessage,
  },
});
