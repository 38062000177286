
/* Dependencies */
import { defineComponent } from 'vue';
import { ErrorMessage } from 'vee-validate';

/**
 * ErrorMessage component
 */
export default defineComponent({
  props: { name: String, id: String },
  components: {
    ErrorMessage,
  },
});
