
/* Dependencies */
import { defineComponent } from 'vue';
import { Field } from 'vee-validate';

/**
 * CheckboxList component
 */
export default defineComponent({
  props: {
    option: {
      type: Object,
      required: true,
    }
  },
  methods: {
    handleInputChange(event: Event, cb: (e: Event) => void) {
      this.$emit('handleChange', event)
    }
  },
  components: {
    Field,
  },
});
