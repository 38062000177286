
/* Dependencies */
import { defineComponent } from 'vue';
import { Field } from 'vee-validate';

// Components
import ErrorMessage from '../ErrorMessage/ErrorMessage.vue';

/**
 * Input component
 */
export default defineComponent({
  props: {
    label: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
      default: () => []
    },
    required: {
      type: Boolean,
      required: false,
    },
    value: {
      type: String,
      required: false,
    },
  },
  components: {
    Field,
    ErrorMessage,
  },
});
