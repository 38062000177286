
/* Dependencies */
import { defineComponent } from 'vue';
import { Field } from 'vee-validate';
import Checkbox from "@/components/Atoms/Checkbox/Checkbox.vue";

/**
 * MarketingPreference component
 */
export default defineComponent({
  components: {
    Checkbox,
    Field,
  },
  methods: {
    handleChange(e: Event) {
      this.$emit('handleChange', e);
    }
  }
});
