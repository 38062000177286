
/* Dependencies */
import { defineComponent } from 'vue';
import { Field } from 'vee-validate';

// Components
import ErrorMessage from '../../Atoms/ErrorMessage/ErrorMessage.vue';
import Checkbox from "@/components/Atoms/Checkbox/Checkbox.vue";

/**
 * CheckboxList component
 */
export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
    },
    full: {
      type: Boolean,
      required: false,
    },    
  },
  components: {
    Checkbox,
    Field,
    ErrorMessage,
  },
  methods: {
    handleChange(e: Event) {
      this.$emit('handleChange', e)
    }
  }
});
