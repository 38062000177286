/* Dependencies */
import { markRaw } from 'vue';
import * as yup from 'yup';
import { KeyValue } from '@/models/KeyValue.model';
import {CheckboxValue} from "@/models/CheckboxValue.model";

/**
 * Generics
 */
export const titleOptions: KeyValue[] = [
  {
    key: 'Miss.',
    value: 'Miss.',
  },
  {
    key: 'Mrs.',
    value: 'Mrs.',
  },
  {
    key: 'Ms.',
    value: 'Ms.',
  },
  {
    key: 'Mr.',
    value: 'Mr.',
  },
  {
    key: 'Dr.',
    value: 'Dr.',
  },
  {
    key: 'Prof.',
    value: 'Prof.',
  },

];

export const turnoverOptions: KeyValue[] = [
  { key: 'Less than 100,000', value: 'Less than 100,000' },
  { key: '100,001 to 250,000', value: '100,001 to 250,000' },
  { key: '250,001 to 500,000', value: '250,001 to 500,000' },
  { key: '500,001 to 1 Million', value: '500,001 to 1 Million' },
  { key: '1 Million to 10 Million', value: '1 Million to 10 Million' },
  { key: 'Over 10 Million', value: 'Over 10 Million' },
];

export const businessTypeOptions: KeyValue[] = [
  { key: 'Airlines', value: 'Airlines' },
  { key: 'Amusement/Recreation Parks', value: 'Amusement/Recreation Parks' },
  { key: 'Antiques/Art Dealers', value: 'Antiques/Art Dealers' },
  { key: 'ATM Cash/Wire Transfers', value: 'ATM Cash/Wire Transfers' },
  { key: 'Automotive', value: 'Automotive' },
  { key: 'Bars/Disco/Nightclubs', value: 'Bars/Disco/Nightclubs' },
  { key: 'Car Rental', value: 'Car Rental' },
  { key: 'Clothing', value: 'Clothing' },
  { key: 'Computer/PC', value: 'Computer/PC' },
  { key: 'Cruise Lines', value: 'Cruise Lines' },
  { key: 'Dept Stores', value: 'Dept Stores' },
  { key: 'Direct Marketing/Selling', value: 'Direct Marketing/Selling' },
  { key: 'Education', value: 'Education' },
  { key: 'Electrical Goods', value: 'Electrical Goods' },
  { key: 'Financial Ins - Bank & Non Bank', value: 'Financial Ins - Bank & Non Bank' },
  { key: 'Food & Beverage Stores', value: 'Food & Beverage Stores' },
  { key: 'Furniture', value: 'Furniture' },
  { key: 'Gaming/Betting', value: 'Gaming/Betting' },
  { key: 'Health & Beauty', value: 'Health & Beauty' },
  { key: 'Hotels', value: 'Hotels' },
  { key: 'Insurances', value: 'Insurances' },
  { key: 'Jewelers', value: 'Jewelers' },
  { key: 'Medical Facilities', value: 'Medical Facilities' },
  { key: 'Membership Clubs/Organisations', value: 'Membership Clubs/Organisations' },
  { key: 'Other Retail Shops', value: 'Other Retail Shops' },
  { key: 'Other Services', value: 'Other Services' },
  { key: 'Parking', value: 'Parking' },
  { key: 'Petrol', value: 'Petrol' },
  { key: 'Photo', value: 'Photo' },
  { key: 'Rental - Not Car', value: 'Rental - Not Car' },
  { key: 'Restaurants', value: 'Restaurants' },
  { key: 'Taxi Cabs', value: 'Taxi Cabs' },
  { key: 'Telecoms', value: 'Telecoms' },
  { key: 'Transportation', value: 'Transportation' },
  { key: 'Travel Agencies', value: 'Travel Agencies' },
  { key: 'TUI/ATI Agencies', value: 'TUI/ATI Agencies' },
  { key: 'Utility Companies', value: 'Utility Companies' },
];

export const salesChannelOptions: CheckboxValue[] = [
  { id: '00N4H00000E0CKV', name: '00N4H00000E0CKV', value: 'eCommerce / Phone Payments' },
  { id: '00N4H00000E0CKT', name: '00N4H00000E0CKT', value: 'Face To Face Transactions' },
];

export const acknowledgementOptions: CheckboxValue[] = [
  { id: 'acknowledgement', name: 'acknowledgement', value: "I confirm that I have authority to submit the details for the business listed above, and I consent to Elavon and Elavon affiliates contacting us."},
];

export const countryCodeOptions: KeyValue[] = [
  { value: 'AT', key: 'Austria' },
  { value: 'BE', key: 'Belgium' },
  { value: 'BG', key: 'Bulgaria' },
  { value: 'HR', key: 'Croatia' },
  { value: 'CY', key: 'Cyprus' },
  { value: 'CZ', key: 'Czechia' },
  { value: 'DK', key: 'Denmark' },
  { value: 'EE', key: 'Estonia' },
  { value: 'FI', key: 'Finland' },
  { value: 'FR', key: 'France' },
  { value: 'DE', key: 'Germany' },
  { value: 'GR', key: 'Greece' },
  { value: 'HU', key: 'Hungary' },
  { value: 'IT', key: 'Italy' },
  { value: 'IS', key: 'Iceland' },
  { value: 'IE', key: 'Ireland' },
  { value: 'LV', key: 'Latvia' },
  { value: 'LI', key: 'Liechtenstein' },
  { value: 'LT', key: 'Lithuania' },
  { value: 'LU', key: 'Luxembourg' },
  { value: 'MT', key: 'Malta' },
  { value: 'NL', key: 'Netherlands' },
  { value: 'NO', key: 'Norway' },
  { value: 'PL', key: 'Poland' },
  { value: 'PT', key: 'Portugal' },
  { value: 'RO', key: 'Romania' },
  { value: 'SK', key: 'Slovakia' },
  { value: 'SI', key: 'Slovenia' },
  { value: 'ES', key: 'Spain' },
  { value: 'SE', key: 'Sweden' },
  { value: 'GB', key: 'United Kingdom' },
];

export const contactOptions: CheckboxValue[] = [
  { id: "00N1p00000JATdl", name: "00N1p00000JATdl", value: 'Email Consent' },
  { id: "00N1p00000JATdo", name: "00N1p00000JATdo", value: 'Phone Consent' },
  { id: "00N1p00000JATdp", name: "00N1p00000JATdp", value: 'SMS Consent' },
];

export const marketingOptions: CheckboxValue[] = [
  { id: "00N4H00000ERk4x", name: "00N4H00000ERk4x", value: 'Email' },
  { id: "00N4H00000ERk52", name: "00N4H00000ERk52", value: 'Phone' },
  { id: "00N4H00000ERk57", name: "00N4H00000ERk57", value: 'SMS' },
];

/**
 * Form Schema
 */
export const formSchema = markRaw(
  yup.object().shape({
    salutation: yup
      .string()
      .oneOf(
        [...titleOptions.map((option) => option.value)],
        'Invalid option selected.'
      )
      .required('Field is required.'),
    first_name: yup
      .string()
      .max(40, 'Maximum of 40 characters allowed.')
      .required('Field is required.'),
    last_name: yup
      .string()
      .max(80, 'Maximum of 80 characters allowed.')
      .required('Field is required.'),
    "company": yup
      .string()
      .max(150, 'Maximum of 150 characters allowed.')
      .required('Field is required.'),
    "00N24000005gAv2": yup
      .string()
      .oneOf(
        [...businessTypeOptions.map((option) => option.value)],
        'Invalid option selected.'
      )
      .required('Field is required.'),
    phone: yup.string().required('Field is required.'),
    email: yup.string().required('Field is required.').email(),
    url: yup
      .string()
      .max(255, 'Maximum of 40 characters allowed.'),
    country_code: yup
      .string()
      .oneOf(
        [...countryCodeOptions.map((option) => option.value)],
        'Invalid option selected.'
      )
      .required('Field is required.'),
    "00N1p00000JALUl": yup
      .string()
      .oneOf(
        [...turnoverOptions.map((option) => option.value)],
        'Invalid option selected.'
      )
      .optional(),
      acknowledgement: yup.bool().oneOf([true],'Field is required.'),     
  })
);
